<template>
    <zw-sidebar @shown="shown" :title="$t('transfer.title.transfer')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-row>
                    <b-col sm="12">
                        <zw-time-group v-model="form.time"
                                       name="time"
                                       :label-prefix="labelPrefix"
                                       validate="required"
                        ></zw-time-group>
                    </b-col>

                    <b-col sm="12">
                        <zw-input-group v-model="form.buffer"
                                        name="buffer"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="12">
                        <zw-input-group v-model="form.direction"
                                        name="direction"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="12">
                        <zw-input-group v-model="form.office"
                                        name="office"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="12">
                        <zw-textarea-group v-model="form.notice"
                                           name="notice"
                                           :label-prefix="labelPrefix"
                        ></zw-textarea-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            labelPrefix: 'transfer.label.'
        }
    },
    methods: {
        ...mapGetters('Transfers', ['getTransfer']),
        shown() {
            this.$store.dispatch('Transfers/fetchTransfer', {
                id: this.payload.id,
            })
                .then((data) => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...{id: this.payload.id},
                        ...JSON.parse(JSON.stringify(this.getTransfer()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Transfers/saveTransfer', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>